import React, { useState } from "react";

// COMPONENTS
import Header from "../../components/Header"
import ContentLandingPage from "../../components/ContentLandingPage";

function LandingPage() {
  const [nav, setNav] = useState(false);

  return (
    <div className="scroll-auto">
      <Header nav={nav} setNav={setNav} />
      {!nav && <ContentLandingPage />}
    </div>
  );
}

export default LandingPage;
