import React from "react";

import logo from "../assets/icon-whatsapp.svg";

//COMPONENTS
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Footer from "./Footer";

function ContentLandingPage() {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
      <a
        href="https://wa.me/55062995528649?text=Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
        target="blank"
      >
        <img
          src={logo}
          alt="Logo do WhatsApp"
          className="fixed bottom-6 right-[3rem] w-[65px] cursor-pointer "
        />
      </a>
    </div>
  );
}

export default ContentLandingPage;
