import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../animation";
import Testimonials from "./Testimonials";

function Section3() {
  const [clientes, setClientes] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clientes < 3000) {
        setClientes(clientes + 1);
      }
    }, 2);

    return () => clearInterval(interval);
  }, [clientes]);

  return (
    <div
      className="w-full h-full p-4 mb-10 bg-white font-[Poppins] "
      id="section3"
    >
      <h1 className="text-center flex items-center justify-center m-auto mb-6 text-4xl font-semibold text-[#115D33]  border-b-2 border-[#115D33] w-[90%] md:w-[30%]">
        Quem Somos
      </h1>
      <div className="p-4 flex flex-col items-center justify-center max-w-[80%] m-auto">
        <p className="text-xl mb-2">
          <span className="font-semibold">Há mais de uma década</span>, a
          Devoolve tem ajudado brasileiros a se informarem contra os abusos
          cometidos pelas instituições financeiras.
        </p>
        <p className="text-xl mb-2">
          Você sabia que muitos contratos de empréstimos e financiamentos
          possuem seguros abusivos embutidos? Muitas vezes, os consumidores nem
          sequer sabem que estão pagando por serviços desnecessários e
          excessivamente caros.
        </p>
        <p className="text-xl mb-2">
          Nós da Devoolve estamos aqui para lutar pelos seus direitos e garantir
          que você não seja explorado por essas práticas abusivas. Nossos
          especialistas em seguros financeiros estão prontos para analisar o seu
          contrato e identificar qualquer irregularidade.
        </p>

        <p className="text-xl">
          Proteja-se hoje mesmo com a Devoolve e tenha a tranquilidade de saber
          que está pagando apenas pelo que é justo e necessário.
        </p>
      </div>
      <div className="flex items-center justify-center mb-8">
        <div>
          <p className="ml-12 text-4xl font-medium text-[#115D33]">
            +{clientes}
          </p>
          <p className="text-xl font-medium">Contratos Revisados</p>
        </div>
      </div>
      <div>
        <p className="text-center flex items-center justify-center m-auto mb-6 text-4xl font-semibold text-[#115D33]  border-b-2 border-[#115D33] w-[90%] md:w-[30%]">
          O que nossos clientes falam de nós
        </p>
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <Testimonials />
        </motion.div>
      </div>
      <div>
        <a
          href="https://www.google.com/maps/place/Devoolve/@-16.7325553,-49.2746777,15z/data=!4m16!1m9!3m8!1s0x6e1388e03983f20f:0x836a8dec3b9a2056!2sDevoolve!8m2!3d-16.7325553!4d-49.2746777!9m1!1b1!16s%2Fg%2F11y4l0w4p7!3m5!1s0x6e1388e03983f20f:0x836a8dec3b9a2056!8m2!3d-16.7325553!4d-49.2746777!16s%2Fg%2F11y4l0w4p7?hl=pt-BR&entry=ttu"
          target="blank"
        >
          <button className="flex w-full h-full max-w-[350px] m-auto mt-10 mb-6  items-center justify-center border p-3 bg-green-600 text-white font-semibold text-lg rounded-3xl cursor-pointer">
            Veja Mais Avaliações
          </button>
        </a>
      </div>
    </div>
  );
}

export default Section3;
