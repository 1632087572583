import React from "react";

import { FaCar } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BsBuildings } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";

import Cars from "../assets/cars.jpg";
import Empresas from "../assets/empresas.webp";
import Pessoas from "../assets/pessoas.jpg";
import Consignado from "../assets/consignado.jpeg";

// COMPONENTES

function Section2() {
  const mensagem = encodeURIComponent(
    "Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
  );

  return (
    <div
      id="section2"
      className="font-[Poppins] flex flex-col bg-gray-100 p-4 w-[100%]"
    >
      <h1 className="text-center flex items-center justify-center m-auto mb-6 text-4xl font-semibold text-[#115D33]  border-b-2 border-[#115D33] w-[90%] md:w-[30%]">
        O que nós podemos fazer por você
      </h1>
      <p className="text-2xl w-[100%] text-center mb-6 text-[#115D33] ">
        Oferecemos uma ampla gama de serviços para atender às necessidades dos
        nossos clientes. Veja algumas áreas que o seguro prestacional atua:
      </p>
      <div className="flex flex-col md:flex-row">
        <div className="w-[100%] md:w-[50%] flex flex-col m-auto justify-center items-center">
          <video
            className="w-[90%] md:w-[80%] h-[400px] mb-3 rounded-2xl shadow-2xl"
            src="/video.mp4"
            controls
          />
          <a
            href={`https://wa.me/55062995528649?text=${mensagem}`}
            target="blank"
          >
            <button className="flex m-auto items-center justify-center border p-3 bg-green-500 text-white font-semibold text-lg rounded-3xl cursor-pointer hover:scale-95 transition-all">
              <FaWhatsapp className="mr-2 text-2xl" />
              Fale com um Especialista
            </button>
          </a>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center m-auto w-[100%] md:w-[50%]">
          <div className="w-[100%]">
            <div className="relative rounded-xl m-2 h-[250px] w-[90%] md:w-[80%] shadow-lg bg-white overflow-hidden hover:scale-95 transition-all">
              <img
                src={Cars}
                alt="Veículo"
                className="absolute inset-0 w-full h-full object-cover opacity-30"
              />
              <div className="absolute bottom-14 left-4 bg-white p-2 rounded-full shadow-lg">
                <FaCar className="text-[40px] text-gray-700" />
              </div>
              <div className="absolute bottom-4 left-4">
                <p className="text-xl font-semibold text-[#292929]">
                  Financiamento Veicular
                </p>
              </div>
            </div>
            <div className="relative rounded-xl m-2 h-[250px] w-[90%] md:w-[80%] shadow-lg bg-white overflow-hidden hover:scale-95 transition-all">
              <img
                src={Consignado}
                alt="Consignado"
                className="absolute inset-0 w-full h-full object-cover opacity-30"
              />
              <div className="absolute bottom-14 left-4 bg-white p-2 rounded-full shadow-lg">
                <MdOutlineAttachMoney className="text-[40px] text-gray-700" />
              </div>
              <div className="absolute bottom-4 left-4">
                <p className="text-xl font-semibold text-[#292929]">
                  Empréstimo Consignado
                </p>
              </div>
            </div>
          </div>
          <div className="w-[100%]">
            <div className="relative rounded-xl m-2 h-[250px] w-[90%] md:w-[80%] shadow-lg bg-white overflow-hidden hover:scale-95 transition-all">
              <img
                src={Pessoas}
                alt="Pessoas"
                className="absolute inset-0 w-full h-full object-cover opacity-30"
              />
              <div className="absolute bottom-14 left-4 bg-white p-2 rounded-full shadow-lg">
                <GiTakeMyMoney className="text-[40px] text-gray-700" />
              </div>
              <div className="absolute bottom-4 left-4">
                <p className="text-xl font-semibold text-[#292929]">
                  Empréstimo Pessoal
                </p>
              </div>
            </div>
            <div className="relative rounded-xl m-2 h-[250px] w-[90%] md:w-[80%] shadow-lg bg-white overflow-hidden hover:scale-95 transition-all">
              <img
                src={Empresas}
                alt="Empresas"
                className="absolute inset-0 w-full h-full object-cover opacity-30"
              />
              <div className="absolute bottom-14 left-4 bg-white p-2 rounded-full shadow-lg">
                <BsBuildings className="text-[40px] text-gray-700" />
              </div>
              <div className="absolute bottom-4 left-4">
                <p className="text-xl font-semibold text-[#292929]">
                  Empréstimo Empresarial
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
