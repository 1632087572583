import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../animation";
import logo from "../assets/logo-white.png";
import { FaWhatsapp } from "react-icons/fa";

function Section1() {
  return (
    <section
      className="w-full h-auto md:h-[400px] flex flex-col sm:flex-row font-[Poppins] p-6 bg-gradient-to-r from-green-700 via-green-600 to-green-500"
      id="section1"
    >
      {/* Left Section */}
      <motion.div
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        className="flex flex-col w-full sm:w-2/3 mt-8 px-6 text-center sm:text-left justify-center items-center sm:items-start"
      >
        <h1 className="text-4xl sm:text-6xl font-bold text-white mt-10 md:mt-0 mb-6">
          DEVOOLVE SOLUÇÕES
        </h1>
        <p className="text-lg text-white mb-6 leading-relaxed max-w-2xl">
          Atuamos na devolução de cobrança indevida (venda casada) por parte das
          instituições financeiras com foco em seguros.
        </p>
        <a
          href="https://wa.me/55062995528649?text=Olá, Conheci a Empresa pelo site e gostaria de obter mais informações."
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="flex items-center justify-center px-6 py-3 bg-green-600 text-white font-semibold text-lg rounded-full hover:bg-green-500 transition-all duration-300 shadow-lg">
            <FaWhatsapp className="mr-2 text-2xl" />
            Fale com um Especialista
          </button>
        </a>
      </motion.div>

      {/* Right Section */}
      <motion.div
        variants={fadeIn("left", 0.1)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        className="hidden sm:flex w-full sm:w-1/3 justify-center items-center mt-8 sm:mt-0"
      >
        <img
          src={logo}
          alt="Logo Devolve Soluções"
          className="w-2/3 sm:w-full max-w-xs sm:max-w-sm"
        />
      </motion.div>
    </section>
  );
}

export default Section1;
