import React from "react";

// ASSETS
import logo from "../assets/logo-white.png";

import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

function Header({ nav, setNav }) {
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <header className="w-full min-h-[80px] flex items-center bg-gradient-to-r from-green-700 to-green-500 shadow-md fixed top-0 z-50">
      <div className="container mx-auto flex justify-between items-center px-6">
        {/* Logo */}
        <div className="flex items-center">
          <img className="w-32 sm:w-40 h-auto" src={logo} alt="Logo Sistema" />
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8">
          {["Home", "Serviços", "Quem Somos", "Perguntas Frequentes"].map(
            (item, index) => (
              <a
                key={index}
                href={`#section${index + 1}`}
                className="text-white text-lg font-medium hover:text-gray-200 transition-all duration-200"
              >
                {item}
              </a>
            )
          )}
        </nav>

        {/* Mobile Menu Toggle */}
        <button
          onClick={handleNav}
          className="md:hidden flex items-center justify-center text-white focus:outline-none"
        >
          {nav ? <AiOutlineClose size={30} /> : <FaBars size={30} />}
        </button>

        {/* Mobile Navigation */}
        <div
          className={`${
            nav ? "translate-x-0" : "translate-x-full"
          } fixed top-0 right-0 w-3/4 h-full bg-green-700 text-white shadow-lg transition-transform duration-300 md:hidden`}
        >
          <ul className="flex flex-col items-center justify-center h-full space-y-6 text-xl">
            {["Home", "Serviços", "Quem Somos", "Perguntas Frequentes"].map(
              (item, index) => (
                <li key={index} className="hover:underline">
                  <a href={`#section${index + 1}`} onClick={handleNav}>
                    {item}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
