import React from "react";

// ICONS
import { BsFillHouseGearFill, BsTelephoneFill } from "react-icons/bs";
import { FaFacebook, FaMapMarkerAlt, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

// ASSETS
import logo from "../assets/logo-white.png";
import logoReclame from "../assets/ver.jpg";

function Footer() {
  return (
    <footer className="w-full bg-gradient-to-r from-[#0a3b21] via-[#115d33] to-[#0a3b21] text-white">
      {/* Logo Section */}
      <div className="py-6 text-center">
        <img className="w-[200px] mx-auto" src={logo} alt="Logo Devoole" />
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-10">
        {/* Contact Information */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Nossos Dados</h2>
          <ul className="space-y-4 text-lg">
            <li className="flex items-center">
              <FaMapMarkerAlt className="mr-3 text-2xl" />
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Av. Rio Verde QD 97 L. 04/04 Sala 2112, Edificio E-Business,
                Vila São Tomaz, Aparecida de Goiânia - GO
              </a>
            </li>
            <li className="flex items-center">
              <BsTelephoneFill className="mr-3 text-2xl" />
              +55 (62) 99552-8649
            </li>
            <li className="flex items-center">
              <MdEmail className="mr-3 text-2xl" />
              <a href="mailto:devoolve@gmail.com" className="hover:underline">
                devoolve@gmail.com
              </a>
            </li>
            <li className="flex items-start">
              <BsFillHouseGearFill className="mr-3 text-2xl" />
              <div>
                <p>Devoole Soluções Financeiras LTDA.</p>
                <p>54.151.327/0001-93</p>
              </div>
            </li>
          </ul>
        </div>

        {/* Social Media */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">
            Siga-nos nas Redes Sociais
          </h2>
          <div className="flex items-center space-x-6">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <FaFacebook size={40} />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:scale-110 transition-transform duration-300"
            >
              <FaInstagram size={40} />
            </a>
          </div>

          <div className="w-[200px] mt-10">
            <img
              className="border-none"
              src={logoReclame}
              alt="logo Reclame Aqui"
            />
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-600 py-4">
        <p className="text-center text-sm text-gray-400">
          &copy; 2024 Devoole Soluções Financeiras - Todos os direitos
          reservados.
        </p>
        <p className="text-center text-sm text-gray-400 mt-2">
          Desenvolvido por Aslin Tecnologia
        </p>
      </div>
    </footer>
  );
}

export default Footer;
