import React, { useState } from "react";

const Accordion = (props) => {
  const [item, setItem] = useState(props.datas);

  const handleToggleActive = () => {
    let newActive = item.active === 1 ? 0 : 1;
    setItem({ ...item, active: newActive });
  };

  return (
    <div
      className={`p-6 border mb-2 rounded-xl w-full md:w-[580px] shadow-lg transition-all duration-500 group ${
        item.active === 1
          ? "bg-gradient-to-r from-[#115D33] to-[#228B22] text-white"
          : "bg-gray-50 border-gray-300"
      }`}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={handleToggleActive}
      >
        <h3
          className={`text-lg font-semibold transition-all duration-300 ${
            item.active === 1 ? "text-white" : "text-gray-800"
          }`}
        >
          {item.question}
        </h3>
        <div
          className={`text-2xl transform transition-transform duration-300 ${
            item.active === 1
              ? "rotate-180 text-white"
              : "rotate-90 text-[#228B22]"
          }`}
        >
          ▼
        </div>
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-500 ${
          item.active === 1 ? "max-h-screen mt-4" : "max-h-0"
        }`}
      >
        <p
          className={`text-base leading-relaxed ${
            item.active === 1 ? "text-white" : "text-gray-600"
          }`}
        >
          {item.answer}
        </p>
      </div>
    </div>
  );
};

export default Accordion;
